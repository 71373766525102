import { Route, Routes, Navigate } from "react-router-dom"
import Swap from "./pages/Swap"
import EscrowInfo from "./pages/Dashboard/EscrowInfo"
import CreditsInfo from "pages/Dashboard/credits"

export default () => (
  <Routes>
    <Route path="/swap" element={<Swap />} />

    <Route path="/escrowinfo" element={<EscrowInfo />} />

    <Route path="/credits" element={<CreditsInfo />} />

    <Route path="*" element={<Navigate to="/swap" replace />} /> 
  </Routes>
)