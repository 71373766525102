import { useState, useMemo, ReactElement, useEffect } from "react"
import styled from "styled-components"

import container from "components/Container"

import lbunImage from "../../images/trio_512.png"; // Import the PNG image 

const Wrapper = styled(container)`
  width: 100%;
  height: auto;
  position: relative;
  color: ${({ theme }) => theme.primary};
  z-index: 1;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const Row = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: space-between;

  & > div {
    flex: 1;
  }

  .left {
    width: 1vw;
    float: left;
    margin-right: 10px;
  }
  .right {
    width: 1vw;
    float: right;
    margin-left: 10px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoint}) {
    display: block;
    gap: unset;

    & > div {
      flex: unset;
      margin-bottom: unset;
    }

    .left {
      width: 100%;
      float: left;
      margin: unset;
    }

    .right {
      width: 100%;
      float: left;
      margin-left: 0px;
      margin-top: 20px;
    }
  }
`

const Footer = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & span {
    opacity: 0.7;
    font-family: OpenSans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoint}) {
    flex-direction: column;
    justify-content: center;
  }
`

const Credits = () => {

  return (
    <Wrapper>
      <Container>
        <br></br>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "px",
            fontSize: "28px",
          }}
        >
          <h1
            style={{ textAlign: "center", fontSize: "48px", color: "#F0F0F0" }}
          >
            $LUNW Staking Solution
          </h1>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h1
            style={{
              textAlign: "center",
              justifyContent: "center",
              fontSize: "20px",
              color: "#F0F0F0",
            }}
          >
            created with the help of the
          </h1>
          <h2 style={{ color: "#CD6141", fontSize: "36px" }}>
            LBUN Project Devs</h2>
            <div>
              <img src={lbunImage} alt="lbunproject" width={120} height={120} /> {/* Use the imported image */}
            </div>
            <h2
            style={{
              textAlign: "center",
              justifyContent: "center",
              fontSize: "14px",
              color: "#F0F0F0",
            }}
          >
            <br></br><br></br>Contact the LBUN Project for a staking
            <br></br> Frontend and cosmwasm smart 
            <br></br> contract for your token.
          </h2>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h1
            style={{
              textAlign: "center",
              justifyContent: "center",
              fontSize: "12px",
              color: "#F0F0F0",
            }}
          >
            <a
              className="main-header-navbar__nav__link"
              href="https://twitter.com/LbunProject"
            >
              {" "}
              @lbunproject
            </a>
          </h1>
        </div>
      </Container>
    </Wrapper>
  )
}

export default Credits
